/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// Components routing
const Dashboard = Loadable(lazy(() => import('views/screens/dashboard/MainDashboard/index')));
const Timesheet = Loadable(lazy(() => import('views/screens/time_sheet_dashboard/index')));
const ProfileSetting = Loadable(lazy(() => import('views/screens/profile/profilesetting')))
const ConsultantUserReport = Loadable(lazy(() => import('views/screens/userReports/consultantUserreport/index')))
const TraningReport = Loadable(lazy(() => import('views/screens/report/TrainingReport/index')))
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UserListCard = Loadable(lazy(() => import('views/screens/dashboard/adminDashboard/submittedlistDashboard/index')));

const AssetTrackinguser= Loadable(lazy(() => import('views/modules/UserModules/assetTracking/index')))
const TalentAcquision= Loadable(lazy(() => import('views/modules/UserModules/TalentAcquision/index')))
const TalentProfileView = Loadable(lazy(()=>import('views/modules/AdminModules/TalentAcquision/TalentprofileView')))
const  TrainingUserDashboard = Loadable(lazy(() => import('views/modules/UserModules/Training/index')))
const UserWeeksheet = Loadable(lazy(() => import('views/screens/dashboard/adminDashboard/userTimesheet/index')));
// ==============================|| MAIN ROUTING ||============================== //

// Supplier routings
const SupplierInvoice = Loadable(lazy(()=>import('views/modules/SupplierModules/Invoice/index')))
const SupplierInvoiceAdmin = Loadable(lazy(()=>import('views/modules/AdminModules/Invoice/index')))


const UserRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <Dashboard />
                }
            ]
        },


        {
            path: '/timesheet',
            element: <Timesheet />
        },
        {
            path: 'timesheet',
            children: [
                {
                    path: 'default',
                    element: <Timesheet />
                }
            ]
        },


        {
            path: '/profilesetting',
            element: <ProfileSetting />
        },
        {
            path: '/userlistcard',
            element: <UserListCard />
        },
        {
            path: 'userlistcard',
            children: [
                {
                    path: 'default',
                    element: <UserListCard />
                }
            ]
        },
        {
            path: `/userlistcard/userweeksheet/:WeekKey/:userKey`,
            element: <UserWeeksheet />
        },
        {
            path: 'userlistcard/userweeksheet',
            children: [
                {
                    path: 'default',
                    element: <UserWeeksheet />
                }
            ]
        },
        {
            path: 'profilesetting',
            children: [
                {
                    path: 'default',
                    element: <ProfileSetting />
                }
            ]
        },

        {
            path: '/consultantreports',
            element: <ConsultantUserReport />
        },
        {
            path: 'consultantreports',
            children: [
                {
                    path: 'default',
                    element: <ConsultantUserReport />
                }
            ]
        },
        {
            path: '/trainingreport',
            element: <TraningReport />
        },
        {
            path: 'trainingreport',
            children: [
                {
                    path: 'default',
                    element: <TraningReport />
                }
            ]
        },
        
        {
            path: '/assettrackinguser',
            element: <AssetTrackinguser />
        },
        {
            path: 'assettrackinguser',
            children: [
                {
                    path: 'default',
                    element: <AssetTrackinguser />
                }
            ]
        },

        {
            path: '/talentacquision',
            element: <TalentAcquision />
        },
        {
            path: 'talentacquision',
            children: [
                {
                    path: 'default',
                    element: <TalentAcquision />
                }
            ]
        },
        {
            path: '/traininguserdashboard',
            element: <TrainingUserDashboard />
        },
        {
            path: 'traininguserdashboard',
            children: [
                {
                    path: 'default',
                    element: <TrainingUserDashboard />
                }
            ]
        },

        
        {
            path: '/talentprofileview',
            element: <TalentProfileView />
        },
        {
            path: 'talentprofileview',
            children: [
                {
                    path: 'default',
                    element: <TalentProfileView />
                }
            ]
        },
        {
            path: '/supplierinvoice',
            element: <SupplierInvoice />
        },
        {
            path: 'supplierinvoice',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoice />
                }
            ]
        },

        {
            path: '/supplierinvoiceadmin',
            element: <SupplierInvoiceAdmin />
        },
        {
            path: 'supplierinvoiceadmin',
            children: [
                {
                    path: 'default',
                    element: <SupplierInvoiceAdmin />
                }
            ]
        },

        // utils routing
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
    ]
};

export default UserRoutes;
