import React from 'react';
import {
    DesktopOutlined, FileOutlined, FileExclamationOutlined, FileExclamationTwoTone, ClockCircleTwoTone,
    PieChartTwoTone, ClockCircleOutlined, ProjectOutlined, AppstoreTwoTone, FundProjectionScreenOutlined,
    UserSwitchOutlined, AppstoreOutlined, FileSearchOutlined, PieChartOutlined, FundTwoTone, IdcardTwoTone,
    TeamOutlined, UserOutlined, SnippetsTwoTone, BankTwoTone, SmileTwoTone, LogoutOutlined
} from '@ant-design/icons';
import {
    IconDashboard, IconClock, IconUserCircle, IconNote, IconSettings, IconFileReport, IconHourglassHigh,IconFiles,IconChartHistogram,
    IconUserStar, IconFileInvoice, IconAsset, IconMailOpened, IconUserCheck, IconGraph, IconUsers, IconUserSquare,
    IconReportAnalytics, IconUserScreen,IconBasket,IconClock2,IconNotebook,IconStatusChange,IconTrack,IconMultiplier2x,
    IconReport,IconInvoice,IconClockHour2,IconUserHexagon,IconTrekking
} from '@tabler/icons-react';
const menuItems = [
    {
        label: 'Dashboard',
        key: 'dashboard',
        path: '/dashboard',
        icon: <IconDashboard stroke={2} />,
        roles: ['ADMIN', 'USER', 'APPROVER','Supplier' ],
    },
    // {
    //     label: 'Dashboard',
    //     key: 'dashboard',
    //     path: '/dashboardsupplier',
    //     icon: <IconDashboard stroke={2} />,
    //     roles: ['Supplier'],
    // },
    {
        label: 'Pending List',
        key: 'userlistcard',
        path: '/userlistcard',
        icon: <IconNote stroke={2} />,
        roles: ['ADMIN', 'APPROVER'],
    },
    {
        label: 'Timesheet',
        key: 'timesheetadmin',
        path: '/timesheetadmin',
        icon: <IconClock stroke={2} />,
        roles: ['ADMIN'],
    },
    {
        label: 'Timesheet',
        key: 'timesheet',
        path: '/timesheet',
        icon: <IconClock stroke={2} />,
        roles: ['APPROVER', 'USER'],
    },
    {
        label: 'Asset Tracking',
        key: 'assettrackinguser',
        path: '/assettrackinguser',
        icon: <IconAsset stroke={2} />,
        roles: ['APPROVER', 'USER'],
    },
    {
        label: 'Talent Acquisition',
        key: 'talentacquision',
        path: '/talentacquision',
        icon: <IconMailOpened stroke={2} />,
        roles: ['APPROVER', 'USER'],
    },
    {
        label: 'Talent Acquisition',
        key: 'talentacquisionsupplier',
        path: '/talentacquisionsupplier',
        icon: <IconMailOpened stroke={2} />,
        roles: ['Supplier'],
    },
    {
        label: 'Asset Tracking',
        key: 'assettrackingadmin',
        path: '/assettrackingadmin',
        icon: <IconAsset stroke={2} />,
        roles: ['ADMIN'],
    },
    {
        label: 'Talent Acquisition',
        key: 'talentacquisiondashboard',
        path: '/talentacquisiondashboard',
        icon: <IconMailOpened stroke={2} />,
        roles: ['ADMIN'],
    },
    {
        label: 'Training',
        key: 'trainingdashboard',
        path: '/trainingdashboard',
        icon: <IconTrekking stroke={2} />,
        roles: ['ADMIN'],
    },
    {
        label: 'Training',
        key: 'traininguserdashboard',
        path: '/traininguserdashboard',
        icon: <IconTrekking stroke={2} />,
        roles: ['USER','APPROVER'],
    },
    {
        label: 'Invoice',
        key: 'supplierinvoiceadmin',
        path: '/supplierinvoiceadmin',
        icon: <IconFileInvoice stroke={2} />,
        roles: ['ADMIN'],
    },
    {
        label: 'Invoice',
        key: 'supplierinvoice',
        path: '/supplierinvoice',
        icon: <IconFileInvoice stroke={2} />,
        roles: ['Supplier'],
    },
    {
        label: 'Consultants',
        key: 'SupplierUser',
        path: '/SupplierUser',
        icon: <IconUserHexagon stroke={2} />,
        roles: ['Supplier'],
    },
    {
        label: 'Admin',
        key: 'admin',
        icon: <IconUserStar stroke={2} />,
        roles: ['ADMIN'],
        children: [
            {
                label: 'Client',
                key: 'client',
                path: '/client',
                icon: <IconUserCheck stroke={2} />,
                roles: ['ADMIN'],
            },
            {
                label: 'Project',
                key: 'project',
                path: '/project',
                icon: <IconGraph stroke={2} />,
                roles: ['ADMIN'],
            },
            {
                label: 'User',
                key: 'user',
                path: '/user',
                icon: <IconUsers stroke={2} />,
                roles: ['ADMIN'],
            },
            {
                label: 'Supplier',
                key: 'supplier',
                path: '/supplier',
                icon: <IconUserSquare stroke={2} />,
                roles: ['ADMIN'],
            },
            {
                label: 'Settings',
                key: 'settings',
                path: '/settings',
                icon: <IconSettings stroke={2} />,
                roles: ['ADMIN'],
            },
        ],
    },
    {
        label: 'Reports',
        key: 'Reports',
        icon: <IconReport stroke={2} />,
        roles: ['ADMIN', 'USER', 'APPROVER', 'Supplier'],

        children: [
            {
                label: 'Approved Hours',
                key: 'consultantreports',
                path: '/consultantreports',
                icon: <IconHourglassHigh stroke={2} />,
                roles: ['USER', 'APPROVER'],
            },
            {
                label: 'Consultant Hours',
                key: 'contractoremp',
                path: '/contractoremp',
                icon: <IconReportAnalytics stroke={2} />,
                roles: ['Supplier'],
            },
            {
                label: 'Training Report',
                key: 'trainingreport',
                path: '/trainingreport',
                icon: <IconReportAnalytics stroke={2} />,
                roles: ['USER','ADMIN'],
            },
            {
                label: 'User Reports',
                icon: <IconFileReport stroke={2} />,
                key: 'userreports',
                roles: ['ADMIN',],
                children: [
                    {
                        label: 'FTE Hours',
                        key: 'fteemp',
                        path: '/fteemp',
                        icon: <IconClockHour2 stroke={2} />,
                        roles: ['ADMIN'],
                    },
                    {
                        label: 'Consultant Hours',
                        key: 'contractoremp',
                        path: '/contractoremp',
                        icon: <IconClock2 stroke={2} />,
                        roles: ['ADMIN'],
                    },
                    {
                        label: 'User Report',
                        key: 'userreport',
                        path: '/userreport',
                        roles: ['ADMIN'],
                        icon: <IconNotebook stroke={2} />,
                    },
                    {
                        label: 'Timesheet Status',
                        icon: <IconStatusChange stroke={2} />,
                        key: 'tsstatusreport',
                        path: '/tsstatusreport',
                        roles: ['ADMIN'],
                    },
                ]
            },
            {
                label: 'Asset Reports',
                icon: <IconBasket stroke={2} />,
                key: 'assetreports',
                roles: ['ADMIN'],
                children: [
                    {
                        label: 'Asset Tracking',
                        key: 'assettrackingreport',
                        path: '/assettrackingreport',
                        icon: <IconTrack stroke={2} />,
                        roles: ['ADMIN'],
                    },
                    {
                        label: 'Asset Tracking Count',
                        key: 'assettrackingcount',
                        path: '/assettrackingcount',
                        icon: <IconMultiplier2x stroke={2} />,
                        roles: ['ADMIN'],
                    },
                ]
            },
            {
                label: 'Supplier Reports',
                icon: <IconUserScreen stroke={2} />,
                key: 'suppliereports',
                roles: ['ADMIN'],
                children: [
                    {
                        label: 'Document Report',
                        key: 'w9coireport',
                        path: '/w9coireport',
                        icon: <IconFiles stroke={2} />,
                        roles: ['ADMIN'],
                    },
                    {
                        label: 'Invoice Trend Report',
                        key: 'invoicegraph',
                        path: '/invoicegraph',
                        icon: <IconChartHistogram stroke={2} />,
                        roles: ['ADMIN'],
                    },
                    {
                        label: 'Invoice Report',
                        key: 'supplierinvoicereport',
                        path: '/supplierinvoicereport',
                        icon: <IconInvoice stroke={2} />,
                        roles: ['ADMIN'],
                    },
                ]
            },
        ],
    },
];

export default menuItems;
