const authenticationUrls = {
    loginUrl: `unsecure/basic/login`,
};

const ClientUrls = {
    getClientUrl: 'secure/client/getclientlist',
    postClientUrl: 'secure/client/addorupdate',
    getAllCleintsUrl: 'secure/getallclients',
    deleteClientUrl: 'secure/delete/client/byid/'
}

const CountryUrls = {
    getCountries: 'secure/user/getAllCountries',
    getAllCountries: 'secure/getallcountries'
}

const StateUrls = {
    getStateByCountry: 'secure/getstatesforcountry'
}

const TrainingUrls = {
    postTrainingSession : 'secure/trainingsection/addorupdate',
    postDocumentUrl : 'secure/trainingDocument/addorupdate',
    postDocUserUrl: 'secure/trainingUserDocument/update',
    getFrequencyUrl : 'secure/getfrequency/training',
    getTrainigSession: 'secure/training/gettrainingsection',
    getUserTrainingReport : 'secure/gethistory/training',
    getTraningHistoryUrl:  'secure/getUsertrainingDocument/statuscount',
    getTraningUserDocUrl:  'secure/gettrainingdocument/byuser',
    getTrainingdocbySes : 'secure/gettrainingdocument/bytrainingsection',
    getTraningWidgetCount : 'secure/gettrainingDocument/count',
    getUserListProjectWise : 'secure/user/getuserlistbyproject',
    getUserListClientWise : 'secure/user/getuserlistbyclient',
    getAllSessionUrl : 'secure/training/gettrainingsection',
    getTraningReportForAdmin : 'secure/gettrainingreport/byuser' ,
}


const ProjectUrls = {
    getProjectUrl: 'secure/project/getprojectlist',
    getProjectbyClientUrl: 'secure/project/getmultiProjectsByClient',
    postProjectUrl: 'secure/project/addorupdate',
    deleteProjectUrl: 'secure/delete/project/byid/',
    getProjectbyClient: 'secure/project/getProjectsByClient/',
    postImageupload: 'secure/projectfile/addorupdate'
}

const SupplierUrls = {
    getSupplierUrl: 'secure/supplier/getSupplier',
    postSupplierUrl: 'secure/supplier/addorUpdate',
    deleteSupplierUrl: 'secure/delete/Supplier/byid/',
    deleteSupplierDocument :'secure/delete/supplierDocument/byid/',
    postSupplierDocumentsUrl:'secure/supplierDocument/addorUpdate',
    deleteDocumentUrl:'secure/delete/supplierDocument/byid/',
    getSupplierbyId:'secure/supplier/getSupplierbyid',
    w9CoireportUrl:'secure/report/supplierDocument',
    supplierInvoiceReportUrl:'secure/report/supplierinvoice'


}
const SupplierInvoiceUrls = {
    getSupplierInvoiceUrl: 'secure/get/allsupplierinvoice/bysupplier',
    postSupplierInvoiceUrl: 'secure/supplierinvoice/addorupdate',
    getSupplierAdminInvoiceUrl: 'secure/get/allsupplieruserlistforadmin?',
    deleteSupplierInvoiceUrl: 'secure/delete/supplierinvoice/'
}

const SuppliernotesUrls = {
    getSuppliernotesUrl: 'secure/get/allsuppliernotes',
    postSuppliernotesUrl: 'secure/add/suppliernotes',
    deleteSuppliernotesUrl: 'secure/delete/suppliernotesby/'
}
const UsersUrls = {
    getUserlist: 'secure/userInfo/getUserInfolist',
    getUserlistAll: 'secure/userInfo/getUserInfolistall',
    postUserUrl: 'secure/user/addorupdate',
    deleteUserUrl: 'secure/delete/userInfo/byid/',
    deleteuserDocument: 'secure/delete/userDocumentby/',
    getUsernotesUrl: 'secure/get/allusernotes',
    postUsernotesUrl: 'secure/add/usernotes',
    deleteUsernotesUrl: 'secure/delete/usernoteby/',
    terminateUserurl: 'secure/user/terminate',
    getApproversUrl:'secure/approver/getapproverlist',
    getUsersbySuppliersUrl:'secure/get/allsupplieruserlist?',
    getImmgUrl:'secure/user/getimmagirationstatus',
    getImmgDoctypeUrl:'secure/user/getimmagirationdocumenttype',
    uploadImmgDocumentUrl:'secure/userdocument/addorupdate'


}

const UserProfileUrls = {
    postUserSettingsUrl: 'secure/user/addorupdate',
    getUserByidUrl: 'secure/user/getByid/'
}

const UserRoleUrls = {
    getUserRolelist: 'secure/Userrole/getUserRolelist',
}

const UserTypeUrls = {
    getUserTypelist: 'secure/UserType/getUserTypelist',
}
const statusUrls = {
    getStatus: 'secure/status/getAllStatus',
}

const userProjectMappingurls = {
    // postUserProjectMapping: 'secure/userProjectMap/addOrUpdateUserProjectMap',
    postUserProjectMapping: 'secure/userProjectMap/addOrUpdateUserProjectMapCheak',
    deleteUserProjectMappingUrl: 'secure/delete/UserProjectMap/byId/',
    getUserprojectMappingByidUrl: 'secure/userProjectMap/getUserProjectMapList',
    getUserProjectsUrl: 'secure/userProjectMap/getUserProjectList',
    getUserProjectsUrlUsingId: 'secure/userProjectMap/getUserProjectMapList'
}

const TimesheetUrls = {
    postTimesheetUrl: 'secure/timeSheet/addTimeSheet',
    postTimesheetAdminUrl: 'secure/timeSheet/addTimeSheet/byadmin',
    getWeeklyTimesheetUrl: 'secure/timesheetweekly/byweektable?',
    getWeeklyTimesheetAdminUrl: 'secure/timesheetweekly/byweektable/admin?',
    getTimesheetUrl: 'secure/timeSheet/getTimeSheet',
    putTimesheetUrl: 'secure/update/timesheetweekly'

    // getWeeklyTimesheetUrl1 :'secure/timesheetweekly/createorgetweekly/timesheet?',

}

const AdminApprovelistUrls = {
    getStatuscountUrl: 'secure/timesheetweekly/getstatuscount',
    getPendingListUrl: 'secure/timesheet/getPendingList',
    getPendingListnewUrl: 'secure/timesheet/getTimeSheetByPendingListNew',
    putPendingListUrl: 'secure/update/timesheetweekly',
    getAssetCountUrl: 'secure/getassetcount'
}

const EmailVrifycationUrls = {
    getSendVerifyMailUrl: 'unsecure/verify/mail',
}

const twoFactorAuthenticationUrls = {
    sendOtpUrl: 'unsecure/sentotp/verify',
    verifyOtpUrl: 'unsecure/user/otpverify',
    setOrforgetPasswordUlr: 'unsecure/user/setpwd',
    forgetPasswordUrl: 'unsecure/user/forgotpassword'
}

const fileController = {
    getFileUrl: 'unsecure/view'
}

const userStatusUrl = {
    getUserStatus: 'secure/timesheetWeek/getUserStatus'
}

const reportUrl = {
    getReport: 'secure/report/getusertypelistduartion',
    sendDurationmail: 'secure/report/usermonthlyduration/mail',
    approveRejectmail: 'secure/update/timesheetweekly',
    getReport2: 'secure/report/getusertypeSupplierlistduartion',
    getUserReportUrl: 'secure/report/getuserreport/byclientproject/countrystate',
    getuserReport: 'secure/report/getuserlistduartion',
    sendDurationmailContractorEmpUrl: 'secure/report/getusertypeSupplierlistduartion/mail?',
    getAssetTrackingCountReportUrl : 'secure/getassetreport/byuser',
    getInvoiceReportgraphUrl:'secure/report/supplierinvoice/forgraph',
    getSuppliereprtUrl:'secure/report/supplierDocument?'

}


const blackListdomainUrl = {
    getBlacklistDomain: 'secure/blacklistdomain/getblacklistdomainlist',
    postBlacklistDomain: 'secure/blacklistdomain/addorupdate',
    deleteBlacklistDomain: 'secure/delete/blacklistdomain/byid/',
}

const holidaysUrl = {
    postHolidaysurl: 'secure/holidays/addorupdate',
    getHolidaysurl: 'secure/holidays/getholidaysclientmapping',
    deleteHolidayUrl: 'secure/delete/holidays/byid/'
}

const TimesheetWeeks = {
    getTimsheetWeekDates: 'secure/timesheet/getweektable',
    getTimesheetbyId: 'secure/timesheet/getweektable/byid'
}

const AssetTracking = {
    getAssettypeUrl: 'secure/getasset/bytype',
    getAssetListadminUrl: 'secure/getassettracking/byuser?',
    postAssetadminUrl: 'secure/addorupdate/assettracking',
    deleteAssetTrackingUrl: 'secure/delete/assettracking/byid/',
    getAssetReportUrl: 'secure/report/assettracking/byassettype?',
    getAssettrackingReportUrl: 'secure/report/assettracking/byassettypeclientuser?'
}

const AssetTrackingComments = {
    postAssetCommentsUrl: 'secure/assetscomments/addorupdate',
    getAssetCommentsUrl: 'secure/getassetscomments/byassetId?',
    getAssetoverallCountUrl: 'secure/getassetcommentcount'
}

const StatusApibyModules = {
    getStatusApi: 'secure/getstatus/bymodule',
    getStatusstateUrl: 'secure/getinterviewstate/bymodule'
}



const makeApi = {
    getMakeUrl: 'secure/getallmakes'
}

const AssetnotesUrl = {
    getAssetNotes: 'secure/getassetsNotes/byassetId?',
    postAssetnotes: 'secure/assetsnotes/addorupdate?',
    deleteAssetnotes: 'secure/terminate/assetsNotes/byid/'
}

const manageProfile = {
    getSKillsUrl: 'secure/getskills',
    postManageprofile: 'secure/userProfile/addorupdate',
    getManageprofileUrl: 'secure/userProfile/getUserProfileList',
    getIntervieweeProfile: 'secure/userprofile/getUserProfilebyinterviewer',

    getSkillsautoCompleteUrl: 'secure/getNewskills?',
    postSkillUrl: 'secure/add/skills',
    getManageprofileByid: 'secure/userprofile/getbyid',
    putmanageProfileUrl: 'secure/updateuserprofile/forpositionandstate',
    getQualificationUrl: 'secure/getallqualifications',
    getPositionDuration:'secure/getallpositionduration',
    getAllexperience:'secure/getallexperience',
    postPorfilenotes:'secure/add/userprofilenotes',
    getProfielNotesbyId:'secure/get/alluserProfileNotes',
    getProfilesource:'secure/getallprofilesource',
    getProfilebyFilterUrl:'secure/getuserProfile/byserarchfilter'

}

const managePosition = {
    postPosition: 'secure/addorupdateposition',
    getPositionUrl: 'secure/get/allPosition',
    deletePositionUrl: 'secure/delete/positionby/'
}
const talentAcquisitionDashboard = {
    getProfilebyStateandPositionUrl: 'secure/getuserprofile/byposition/andstate?',
    getPorfileCountbyPositionUrl:'secure/userprofile/getstatecount?',
    getUnallocCount:'secure/userprofile/getUnallocatedCount',
    feedbackProfileUrl:'secure/addorupdateInterview',
    addOrsubProfilecardUrl:'secure/updateposition/interviewcount'
}


export {
    authenticationUrls, blackListdomainUrl, reportUrl, holidaysUrl,
    fileController, UserProfileUrls, twoFactorAuthenticationUrls,talentAcquisitionDashboard,
    TimesheetUrls, userProjectMappingurls, UserRoleUrls, statusUrls, 
    UserTypeUrls, ClientUrls, UsersUrls, ProjectUrls, SupplierUrls,
    TimesheetWeeks, AssetTracking, makeApi, managePosition,TrainingUrls,
    AdminApprovelistUrls, EmailVrifycationUrls, userStatusUrl, CountryUrls, SuppliernotesUrls,
    StateUrls, AssetTrackingComments, StatusApibyModules, AssetnotesUrl, manageProfile,SupplierInvoiceUrls
}
