// assets
import { IconBrandChrome, IconArrowDownRightCircle, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconAperture, IconCode, IconSettings, IconDeviceDesktop, IconFileText, IconUser, IconReportSearch, IconHome, IconUserCircle } from '@tabler/icons';
// constant
const icons = { IconBrandChrome, IconArrowDownRightCircle, IconServer, IconBoxMargin, IconHelp, IconDashboard, IconAperture, IconCode, IconSettings, IconFileText, IconDeviceDesktop, IconUser, IconReportSearch, IconHome, IconUserCircle };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'userlistcard',
            title: 'Pending List',
            type: 'item',
            url: '/userlistcard',
            icon: icons.IconReportSearch,
            breadcrumbs: false
        },
        {
            id: 'timesheetadmin',
            title: 'Timesheet',
            type: 'item',
            url: '/timesheetadmin',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'assettrackingadmin',
            title: 'Asset Tracking',
            type: 'item',
            url: '/assettrackingadmin',
            icon: icons.IconAperture,
            breadcrumbs: false
        },
        {
            id: 'talentacquisiondashboard',
            title: 'Talent Acquisition',
            type: 'item',
            url: '/talentacquisiondashboard',
            icon: icons.IconAperture,
            breadcrumbs: false
        },
        {
            id: 'supplierinvoiceadmin',
            title: 'Invoice',
            type: 'item',
            url: '/supplierinvoiceadmin',
            icon: icons.IconServer,
            breadcrumbs: false
        },
        {
            id: 'admin',
            title: 'Admin',
            type: 'collapse',
            url: '',
            icon: icons.IconAperture,
            external: false,
            children: [
                {
                    id: 'client',
                    title: 'Client',
                    type: 'item',
                    url: '/client',
                    icon: icons.IconServer,
                    breadcrumbs: false
                },
                {
                    id: 'project',
                    title: 'Project',
                    type: 'item',
                    url: '/project',
                    icon: icons.IconBoxMargin,
                    breadcrumbs: false
                },
                {
                    id: 'user',
                    title: 'User',
                    type: 'item',
                    url: '/user',
                    icon: icons.IconUser,
                    breadcrumbs: false
                },
                {
                    id: 'supplier',
                    title: 'Supplier',
                    type: 'item',
                    url: '/supplier',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'item',
                    url: '/settings',
                    icon: icons.IconSettings,
                    breadcrumbs: false
                },

            ]
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            url: '',
            icon: icons.IconAperture,
            external: false,
            children: [
                {
                    id: 'reports',
                    title: 'User Reports',
                    type: 'collapse',
                    url: '',
                    icon: icons.IconAperture,
                    external: false,
                    children: [
                        {
                            id: 'fteemp',
                            title: 'FTE Hours',
                            type: 'item',
                            url: '/fteemp',
                            icon: icons.IconServer,
                            breadcrumbs: false,
                        },
                        {
                            id: 'contractoremp',
                            title: 'Consultant Hours',
                            type: 'item',
                            url: '/contractoremp',
                            icon: icons.IconBoxMargin,
                            breadcrumbs: false
                        },
                        {
                            id: 'userreport',
                            title: 'User Report',
                            type: 'item',
                            url: '/userreport',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'tsstatusreport',
                            title: 'Timesheet Status Report',
                            type: 'item',
                            url: '/tsstatusreport',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                    ]
                },
                {
                    id: 'reports',
                    title: 'Assets Reports',
                    type: 'collapse',
                    url: '',
                    icon: icons.IconAperture,
                    external: false,
                    children: [
                        {
                            id: 'assettrackingreport',
                            title: 'Asset Tracking Report',
                            type: 'item',
                            url: '/assettrackingreport',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'assettrackingcount',
                            title: 'Asset Tracking Count',
                            type: 'item',
                            url: '/assettrackingcount',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                    ]
                },

                {
                    id: 'suppliereport',
                    title: 'Supplier Reports',
                    type: 'collapse',
                    url: '',
                    icon: icons.IconAperture,
                    external: false,
                    children: [
                        {
                            id: 'w9coireport',
                            title: 'Document Report',
                            type: 'item',
                            url: '/w9coireport',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'invoicegraph',
                            title: 'Invoice Trend Report',
                            type: 'item',
                            url: '/invoicegraph',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                        {
                            id: 'supplierinvoicereport',
                            title: 'Invoice Report',
                            type: 'item',
                            url: '/supplierinvoicereport',
                            icon: icons.IconUserCircle,
                            breadcrumbs: false
                        },
                    ]
                },
                // {
                //     id: 'invoice',
                //     title: 'Invoice Reports',
                //     type: 'collapse',
                //     url: '',
                //     icon: icons.IconAperture,
                //     external: false,
                //     children: [
                //         {
                //             id: 'invoicegraph',
                //             title: 'Invoice Report',
                //             type: 'item',
                //             url: '/invoicegraph',
                //             icon: icons.IconUserCircle,
                //             breadcrumbs: false
                //         },
                //     ]
                // },

            ]
        },
        // {
        //     id: 'reports',
        //     title: 'Reports',
        //     type: 'collapse',
        //     url: '',
        //     icon: icons.IconAperture,
        //     external: false,
        //     children: [
        //         {
        //             id: 'reports',
        //             title: 'Assets Reports',
        //             type: 'collapse',
        //             url: '',
        //             icon: icons.IconAperture,
        //             external: false,
        //             children: [

        //             ]
        //         },




        //     ]
        // },

    ]
};

// const other = {
//     id: 'sample-docs-roadmap',
//     type: 'group',
//     children: [
//         {
//             id: 'dashboard',
//             title: 'Dashboard',
//             type: 'item',
//             url: '/dashboard',
//             icon: icons.IconDashboard,
//             breadcrumbs: false
//         },
//         {
//             id: 'admin',
//             title: 'Admin',
//             type: 'collapse',
//             icon: icons.IconWindmill,
//             children: [
//                 {
//                     id: 'client',
//                     title: 'Client',
//                     type: 'item',
//                     url: '/',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'project',
//                     title: 'Project',
//                     type: 'item',
//                     url: '/',
//                     breadcrumbs: false
//                 }
//             ]
//         }
//     ]
// }
export default other;
