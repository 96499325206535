import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiService from "../../../../api/apiService";
import { authenticationUrls, twoFactorAuthenticationUrls } from '../../../../api/apiUrls'
import Loader from '../../../../ui-component/Loader'
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import Notification from 'ui-component/notification/Notification'
import { gridSpacing } from 'store/constant';
import moment from 'moment';
import { showConfirmationAlertPassword } from 'ui-component/confirmbox/confirmBoxpasswordReset';
// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
    const apiUrls = { ...authenticationUrls, ...twoFactorAuthenticationUrls };
    const navigate = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [trustedEmail, settrustedEmail] = useState('')
    const [isFieldsFilled, setIsFieldsFilled] = useState(false);

    var checked1 = false;
    const [password, setPassword] = useState("");
    const googleHandler = async () => {
        console.error('Login');
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // const gotoSuccessPage = () => {
    //     navigate('/dashboard');  
    // };

    const gotoForgotPage = () => {
        localStorage.clear()
        navigate('/forgotpassword');
    };

    useEffect(() => {
        // checktrustedEmail()
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const passwordRegex = /.{6,}$/;

        const isEmailValid = emailRegex.test(email);
        const isPasswordValid = passwordRegex.test(password);
        setIsFieldsFilled(isEmailValid && isPasswordValid);
        checkisLoggedin()
    }, [email, password])


    const checkisLoggedin = () => {
        var loggedIn = localStorage.getItem("isLoggedin");
        if (loggedIn) {
            navigate('/dashboard')
        } else {
            navigate('/login')
            localStorage.clear()
        }

    }
    const checktrustedEmail = (result) => {
        var trustedCookie = document.cookie;
        console.log(trustedCookie);
        const cookiesArray = trustedCookie.split(';');
        let isTrustedValue = null;
        for (let i = 0; i < cookiesArray.length; i++) {
            const cookie = cookiesArray[i].trim();
            const [name, value] = cookie.split('=');
            if (name === 'isTrusted') {
                isTrustedValue = value;
                break;
            }
        }
        if (isTrustedValue === 'true') {
            navigate(`/dashboard`);
            window.location.reload();
        } else {
            navigate(`/verifycode?email=${result.data.data.userInfo.email}&id=${result.data.data.userInfo.id}`);
            verifyCode(result.data.data.userInfo.email);
            window.location.reload();
        }

    }

    const gotoSuccessPage = () => {
        console.log('submit clicked');
        localStorage.clear();
        // checktrustedEmail()

        setIsLoading(true);
        var validRegex = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
        apiService('unsecure/basic/login', 'post', { email: email, password: password }, false, '',
            async result => {
                console.log(result)

                if (result.data.message == "Password has been Expired.Please reset your passord") {
                    setIsLoading(false);
                    const val = await showConfirmationAlertPassword();
                    if (val.isConfirmed) {
                        navigate('/forgotpassword')
                    }
                } else {
                    if (result.data.success == true) {
                        Notification.openNotificationSuccess(result.data.message)
                     if(result.data.data.type=='SUPPLIER'){
                        var userRole={name:'Supplier',id:'10'}
                        localStorage.setItem('jwt', result.data.data.jwt);
                        localStorage.setItem('UserData', JSON.stringify(result.data.data.supplier));
                        localStorage.setItem('userRole', JSON.stringify(userRole));
                        localStorage.setItem('loogesdUsertype', result.data.data.type);
                        localStorage.setItem('isLoggedin', true);
                        localStorage.setItem('SessionId', (result.data.data.id));
                        navigate(`/dashboard`);
                     }else{
                        localStorage.setItem('login', true);
                        localStorage.setItem('loginData', JSON.stringify(result.data));
                        localStorage.setItem('jwt', result.data.data.jwt);
                        localStorage.setItem('UserData', JSON.stringify(result.data.data.userInfo));
                        localStorage.setItem('UserDataemail', JSON.stringify(result.data.data.userInfo.email));
                        localStorage.setItem('userRole', JSON.stringify(result.data.data.userInfo.userRole));
                        localStorage.setItem('SessionId', (result.data.data.id));
                        localStorage.setItem('isLoggedin', true);
                        localStorage.setItem('loogesdUsertype', result.data.data.type);
                        localStorage.setItem('loggedDate', moment().format('YYYY-MM-DD'));
                        checktrustedEmail(result)
                        setIsLoading(false);
                        console.log(result);
                     }
                    } else {
                        setIsLoading(false);
                        Notification.openNotificationFaliure(result.data.message)
                    }
                }
            },
            (error) => {
            });

    };
    // verify code after login
    const verifyCode = (email) => {
        apiService(apiUrls.forgetPasswordUrl + '?email=' + email, 'GET', '', false, '',
            (result) => {
                console.log(result)
                if (result.data.success) {
                    Notification.openNotificationSuccess('Your Verification Code is sent');
                } else {
                    Notification.openNotificationFaliure(result.data.message);
                }
            })
    }

    const checkTrustexpiryDate = (trustedDate) => {
        const currentDate = moment().format('YYYY-MM-DD')
        // if (moment(currentDate).isAfter(trustedDate)) {
        //     Notification.openNotificationFaliure('Your Trust Verification is Expired Pls Verify Again')

        // }moment(currentDate).isAfter(trustedDate)
        return moment(currentDate).isAfter(trustedDate);
    }


    // const isCheckedverify = (checked) => {
    //     if (checked) {
    //         localStorage.setItem('isVerified', checked)
    //         isVerified == false ? navigate('/verifycode') : null
    //         setChecked(true)
    //     } else {
    //         navigate('/')
    //     }
    // }

    const handleCheckbox = (x) => {
        setChecked(x.target.checked)
    }

    return (
        <>
            {isLoading && <Loader />}
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Login with Email address</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    email: 'test@gmail.com',
                    password: '123456',
                    submit: true
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                            gotoSuccessPage();
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={email}
                                name="email"
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                label="Email Address / Username"
                                inputProps={{}}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheckbox}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Remember Your Password"
                            /> */}
                            {/* <Typography onClick={gotoForgotPage} variant="subtitle1" color="secondary" 
                            sx={{ textDecoration: 'none', cursor: 'pointer' }} className='cursor_pointer' style={{cursor:'pointer'}}>
                                Forgot Password?
                            </Typography> */}
                            <Button onClick={gotoForgotPage} variant="subtitle1" sx={{ textDecoration: 'none', cursor: 'pointer' }} className='forget_pass'>
                                Forgot Password?
                            </Button>
                            {/* <h2
                                className='forget_pass'
                              onClick={gotoForgotPage}
                            >
                                Forgot Password?
                            </h2> */}
                        </Stack>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} >
                                <Grid container spacing={gridSpacing}>
                                    {/* <Grid item lg={12} md={6} sm={6} xs={6}>
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                className='login_btn'
                                                variant="contained"
                                            // color="secondary"
                                            >
                                                Login
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </Grid> */}
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{ mt: 2 }}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting || !isFieldsFilled}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    Login
                                                </Button>
                                            </AnimateButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}

            </Formik>
        </>
    );
};

export default FirebaseLogin;
